// extracted by mini-css-extract-plugin
export var coordinatesButton = "locations-module--coordinates-button--1ZQ-y";
export var deleteForm = "locations-module--delete-form--TgxOd";
export var description = "locations-module--description--cWo3O";
export var divider = "locations-module--divider--TEJX7";
export var form = "locations-module--form--i-ltv";
export var icon = "locations-module--icon--hhM0Q";
export var importDownloadExample = "locations-module--import-download-example--yotVQ";
export var importForm = "locations-module--import-form--gLRLO";
export var locationsWrapper = "locations-module--locationsWrapper--IQrg1";
export var toggle = "locations-module--toggle--NBy6N";
export var toggleField = "locations-module--toggle-field--kiEBN";
export var toggleLabel = "locations-module--toggle-label--dzCJ0";
export var warehouseDescription = "locations-module--warehouse-description--HIHDZ";