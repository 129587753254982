import React from 'react'
import * as tableStyles from '../../common/table/table.module.scss'
import classNames from 'classnames'
import { TableColumnCellWrapperProps } from './types'

export const TableColumnCellWrapper = ({
   content,
   disabled,
}: TableColumnCellWrapperProps) => (
   <div
      className={classNames(tableStyles.tableColumnCellWrapper, {
         [tableStyles.tableColumnCellWrapperDisabled]: disabled,
      })}
   >
      {content}
   </div>
)
