import React from 'react'

import Connections from '../components/views/connections/connections'
import { LayoutLoggedIn } from '../components/views/layout'

export default function ConnectionsPage() {
   return (
      <LayoutLoggedIn>
         <Connections />
      </LayoutLoggedIn>
   )
}
