// extracted by mini-css-extract-plugin
export var addItemToggle = "connections-module--addItemToggle--KzTFi";
export var coordinatesButton = "connections-module--coordinates-button--3fwsS";
export var deleteForm = "connections-module--delete-form--pqNQ7";
export var description = "connections-module--description--TnXvL";
export var divider = "connections-module--divider--sqKzP";
export var form = "connections-module--form--eiDr-";
export var icon = "connections-module--icon--+sMpd";
export var importDownloadExample = "connections-module--import-download-example--+C4x8";
export var importForm = "connections-module--import-form--1Rs0D";
export var locationsWrapper = "connections-module--locationsWrapper--Grjap";
export var toggle = "connections-module--toggle--Qa1N2";
export var toggleField = "connections-module--toggle-field--szdV1";
export var toggleLabel = "connections-module--toggle-label--AT3sQ";
export var toggleLabelDisabled = "connections-module--toggle-label-disabled--bdB5Y";
export var warehouseDescription = "connections-module--warehouse-description--qvbbZ";