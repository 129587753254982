import React, { useState, useEffect, useMemo, useRef } from 'react'
import * as yup from 'yup'
import { Col, Row } from 'react-grid-system'

import { TableWrapper } from '../../common/table/TableWrapper'
import {
   Alert,
   CsvDownloadForm,
   PageHeader,
   Image,
   Search,
   Modal,
   TrueOrFalse,
   Button,
   CrudActionButtons,
   LoadingOverlay,
   SnackBar,
   SlidePanel,
   Filters,
   Svg,
   ImportModal,
} from '../../common'
import { Dropdown, Input, Toggle } from '../../common/form/fields'
import FilterButton from '../../common/filters/filterButton/filterButton'
import FilterTags from '../../common/filters/filterTags/filterTags'
import { Actions, PAGINATION } from '../../../constants/tables'
import { Severity } from '../../../types'
import {
   allRoleOptions,
   CRUD_TABLE,
   defaultConnection,
   defaultConnectionLimitMessage,
   defaultSnackbar,
   itemDropDownChangeConstants,
   trialConnectionLimitMessage,
   warningModalRoleChangeMessage,
} from './constants'
import { DropdownFilter, TextFilter } from '../../common/filters/filterTypes'
import { isEmptyObject } from '../../../common/utils/functions'
import { convertLocationToOptions } from '../../common/filters/helpers'
import { PATH } from '../../../constants/global'
import { isEmployeeRole, UserRoles } from '../../../common/utils/roles'
import {
   useCrudItems,
   useFilters,
   useForm,
   useActiveLocations,
   useSettings,
   useProfile,
   useConnections,
} from '../../../hooks'

import * as styles from '../../views/history/itemHistory/itemHistory.module.scss'
import * as connectionsStyles from './connections.module.scss'

import {
   Filters as FiltersObject,
   FiltersForAPI,
} from '../../../hooks/useFilters/useFilters.type'
import { Option } from '../../common/form/fields/dropdown/dropdown.type'
import { format } from 'date-fns'
import { FORMATS } from '../../../constants/datetime'
import { flattenFilterObj } from '../../../hooks/useFilters/useFilters.helpers'
import * as locationStyles from '../locations/locations.module.scss'
import classNames from 'classnames'
import { WarningModal } from '../../common/warningModal/warningModal'
import { isTrialPlan } from '../../../common/utils/planTypes'
import { TableColumnCellWrapper } from '../../common/table/tableColumnCellWrapper'
import { SnackBarState } from '../../common/snackBar/types'
import { BsCloudArrowUp as ImportIcon } from 'react-icons/bs'

const {
   extraClassName,
   modalTitle,
   placeholder,
   addButtonText,
   key,
   crudPaths,
   tableTitle,
   downloadRequest,
} = CRUD_TABLE

const errorMsg = 'This field is required'

const filterSchema = yup.object().shape({})

const connectionSchema = yup.object().shape({
   ID: yup.string(),
   FirstName: yup.string().required(errorMsg),
   LastName: yup.string().required(errorMsg),
   RoleId: yup.string().required(errorMsg),
   Email: yup
      .string()
      .email()
      .when('RoleId', {
         is: (roleID) => roleID !== UserRoles.VIRTUAL.toString(),
         then: yup
            .string()
            .email('This is not a valid email address')
            .required(errorMsg),
      }),
   Password: yup.string().when(['RoleId', 'ID'], {
      is: (roleID, id) => !id && roleID !== UserRoles.VIRTUAL.toString(),
      then: yup.string().required(errorMsg),
   }),
   ConfirmPassword: yup
      .string()
      .when(['RoleId', 'ID'], {
         is: (roleID, id) => !id && roleID !== UserRoles.VIRTUAL.toString(),
         then: yup.string().required(errorMsg),
      })
      .when(['Password', 'ID'], {
         is: (password, id) => !id && password,
         then: yup
            .string()
            .required('The confirm new password field is required.')
            .oneOf(
               [yup.ref('Password'), null],
               'The new password and confirm new password do not match.'
            ),
      }),
})

function formatFiltersForAPI(filters: FiltersObject): FiltersForAPI {
   function formatDate(date: Date) {
      return format(date, FORMATS.filterDateAPI)
   }

   const startDateFilter = {
      ...(filters?.startDate?.value instanceof Date && {
         startDate: formatDate(filters?.startDate?.value),
      }),
   }
   const endDateFilter = {
      ...(filters?.endDate?.value instanceof Date && {
         endDate: formatDate(filters?.endDate?.value),
      }),
   }

   const status = Array.isArray(filters?.status?.value)
      ? filters?.status.value[0].value
      : null

   return {
      ...flattenFilterObj(filters),
      ...startDateFilter,
      ...endDateFilter,
      status,
   }
}

const Connections = () => {
   const { query, skip, take } = PAGINATION
   const {
      error,
      data: crudData,
      get,
      update,
      add,
      remove,
      totalItems,
      setPath,
      isLoading,
      isRefetching,
      isCreating,
      isSuccessCreate,
      isErrorCreate,
      isUpdating,
      isSuccessUpdate,
      isErrorUpdate,
      isRemoving,
      isSuccessRemove,
      isErrorRemove,
      refresh: refreshConnections,
   } = useCrudItems({ query, skip, take, apiMethod: 'post' })
   const { list: locations } = useActiveLocations({ warehousesOnly: true })
   const {
      errors,
      modify,
      reset,
      remove: removeFilter,
      save,
      savedFilters,
      tmpFilters,
   } = useFilters(filterSchema, {})
   const { settings } = useSettings()
   const { profile, refresh: refetchProfile } = useProfile()

   const {
      importConnections,
      isImportSuccess,
      isImporting,
      importError,
      importFileErrors,
      totalImported,
   } = useConnections()

   const { onSubmit, validationErrors, onReset } = useForm(
      connectionSchema,
      (values) => {
         // remove properties we don't need
         const { Location, Role, ...formValues } = values
         if (formValues?.ID) {
            update(formValues)
         } else {
            add(formValues)
         }
      }
   )

   const [searchMode, setSearchMode] = useState<boolean>(false)
   const [pageSized, setPageSized] = useState<number>(PAGINATION.pageSized)
   const [isPageReset] = useState<boolean>(false)
   const [isAddEditModalVisible, setIsAddEditModalVisible] =
      useState<boolean>(false)
   const [isDeleteModalVisible, setIsDeleteModalVisible] =
      useState<boolean>(false)
   const [isRoleChangeWarningVisible, setIsRoleChangeWarningVisible] =
      useState<boolean>(false)
   const [isExportModalVisible, setIsExportModalVisible] =
      useState<boolean>(false)
   const [openWarningModal, setOpenWarningModal] = useState<boolean>(false)
   const [searchCriteria, setSearchCriteria] = useState('')
   const [snackbarState, setSnackbarState] =
      useState<SnackBarState>(defaultSnackbar)
   const [filtersOpen, setFiltersOpen] = useState<boolean>(false)
   const [isDownloading, setIsDownloading] = useState<boolean>(false)
   const [filterValues, setFilterValues] = useState({})
   const [isConnectionLimitPromptVisible, setIsConnectionLimitPromptVisible] =
      useState<boolean>(false)
   const [selectedConnection, setSelectedConnection] =
      useState(defaultConnection)
   const [isImportModalVisible, setIsImportModalVisible] =
      useState<boolean>(false)

   const handleOpenImportModal = () => setIsImportModalVisible(true)

   const formRef = useRef<HTMLFormElement>()
   const buttonRef = useRef<HTMLInputElement | null>(null)

   const locationOptions = convertLocationToOptions(locations)

   const resetAndCloseModal = () => {
      setIsImportModalVisible(false)
      setSelectedConnection(defaultConnection)
      setIsAddEditModalVisible(false)
      setIsDeleteModalVisible(false)
      setIsExportModalVisible(false)
   }

   const applySavedFilters = (filters: FiltersObject) => {
      setFiltersOpen(false)
      handleGetItems(searchCriteria, 0, pageSized, filters)
      setFilterValues(formatFiltersForAPI(filters))
   }

   const handleGetItems = (
      query?: string,
      skip?: number,
      take?: number,
      filters?: {}
   ) => {
      const formattedFilters = formatFiltersForAPI(filters || savedFilters)
      setFilterValues(formattedFilters)

      get(query, skip, take, formattedFilters)
   }

   const handleSearch = (
      query?: string,
      skip?: number,
      take?: number,
      filters?: {}
   ) => {
      const formattedFilters = formatFiltersForAPI(filters || savedFilters)
      setFilterValues({ ...formattedFilters, query })

      get(query, skip, take, formattedFilters)
   }

   const handleClose = () => {
      onReset()
      resetAndCloseModal()
   }

   const showActionForm = (action: Actions, id?: string) => {
      if (action === Actions.Edit || action === Actions.View) {
         const connectionToEdit =
            crudData?.find((connection) => connection?.ID === id) || {}

         setSelectedConnection({
            // @ts-ignore
            ...connectionToEdit,
            Location: locationOptions?.find(
               (location) =>
                  location.value === connectionToEdit?.LocationId?.toString()
            ),
            Role: allRoleOptions?.find(
               (role) => role.value === connectionToEdit?.RoleId?.toString()
            ),
         })

         setIsAddEditModalVisible(true)
      } else if (action === Actions.Delete) {
         const connectionToEdit = crudData.find(
            (connection) => connection?.ID === id
         )

         setSelectedConnection(connectionToEdit)
         setIsDeleteModalVisible(true)
      } else {
         setIsAddEditModalVisible(true)
      }
   }

   const handleItemDropdownChange = (fieldName: string, option: Option) => {
      if (fieldName === itemDropDownChangeConstants.ROLE) {
         const reachedVirtualLimit =
            profile.AllowedToAddNewVirtualUser === false &&
            option?.value === String(UserRoles.VIRTUAL)
         const reachedEmployeeAdminLimit =
            profile.EnterpriseUsersUsed >= profile.EnterpriseUsersAllowed &&
            (option?.value === String(UserRoles.EMPLOYEE) ||
               option?.value === String(UserRoles.ADMIN))
         // adding a connection
         if (!isEditing && (reachedVirtualLimit || reachedEmployeeAdminLimit)) {
            return setIsConnectionLimitPromptVisible(true)
         }
         // editing a connection
         if (isEditing) {
            setIsRoleChangeWarningVisible(true)
         }
      }
      setSelectedConnection((prevState) => ({
         ...prevState,
         [fieldName]: option,
         RoleId: fieldName === 'Role' ? option?.value : prevState.RoleId,
      }))
   }

   const handleToggle = (name: string, checked: boolean) => {
      const value = name === 'RestrictItemDelete' ? !checked : checked
      setSelectedConnection((prevState) => ({
         ...prevState,
         [name]: value,
      }))
   }

   const handleDropdownChange = (
      name: string,
      label: string,
      options: Option[] | Option
   ) => {
      const filterOptions = Array.isArray(options)
         ? options?.map((option) => ({
              label: option.label,
              value: option.value,
           }))
         : [options]

      modify(name, label, !!options ? filterOptions : [])
   }

   const handleRemoveTag = (name: string, id: string): void => {
      const newFilters = removeFilter(name, id)
      applySavedFilters(newFilters)
   }

   const handleFilterSave = async () => {
      const isSaved = await save()

      if (isSaved) {
         applySavedFilters(tmpFilters)
      }
   }

   const handleResetFilters = () => {
      formRef.current.reset()
      reset()
      applySavedFilters({})
   }

   const handleDeleteConnection = () => {
      remove({ UserId: selectedConnection?.ID })
   }

   const showDownloadModal = () => {
      setIsExportModalVisible(true)
   }

   const isVirtualConnection =
      selectedConnection?.Role?.value === UserRoles.VIRTUAL.toString()
   const isEmployeeConnection =
      selectedConnection?.Role?.value === UserRoles.EMPLOYEE.toString()
   const isAdminConnection =
      selectedConnection?.Role?.value === UserRoles.ADMIN.toString()
   const isEditing = !!selectedConnection?.ID

   const connectionsLimitReached = error && error.includes('Connections limit')

   const isEmployee =
      profile?.Roles?.length && isEmployeeRole(profile.Roles) ? true : false

   const roleOptions = useMemo((): Option[] => {
      const isEditing = !!selectedConnection?.ID
      // Add modal options
      if (!isEditing) {
         return allRoleOptions
      }

      // Edit modal options
      if (isEditing) {
         return selectedConnection.RoleId == UserRoles.VIRTUAL
            ? allRoleOptions.filter(
                 (option) => option.value === UserRoles.VIRTUAL.toString()
              )
            : allRoleOptions.filter(
                 (option) => option.value !== UserRoles.VIRTUAL.toString()
              )
      }
   }, [profile, selectedConnection])

   const roleDropdownDisabled = useMemo(
      () =>
         isEditing &&
         (isEmployee ||
            selectedConnection?.RoleId === UserRoles.VIRTUAL.toString() ||
            roleOptions.length === 1),
      [selectedConnection, isEditing]
   )

   const toggleLabelClassName = isEmployee
      ? connectionsStyles.toggleLabelDisabled
      : connectionsStyles.toggleLabel

   const connectionLimitMessage = useMemo(
      () =>
         isTrialPlan(profile)
            ? trialConnectionLimitMessage
            : defaultConnectionLimitMessage,
      [profile]
   )

   // employees can view the connection modal on row click
   const handleRowClick = (connectionId: string) =>
      showActionForm(Actions.View, connectionId)

   const enableEditing = useMemo(() => !isEmployee, [isEmployee])
   const enableDeletion = useMemo(() => !isEmployee, [isEmployee])
   const enableViewing = useMemo(() => isEmployee, [isEmployee])

   const rowIsDisabled = (row) => {
      const { Email, RoleId } = row.original
      return profile?.Email === Email || RoleId === UserRoles.COMPANY.toString()
   }

   const columns = useMemo(
      () => [
         {
            Header: 'Actions',
            accessor: 'ID',
            minWidth: 80,
            align: 'center',
            Cell: (cell) => (
               <CrudActionButtons
                  cell={cell}
                  action={showActionForm}
                  editAction={enableEditing && !rowIsDisabled(cell.row)}
                  deleteAction={enableDeletion && !rowIsDisabled(cell.row)}
                  viewAction={enableViewing && !rowIsDisabled(cell.row)}
               />
            ),
         },
         {
            Header: '',
            accessor: 'PictureURL',
            maxWidth: 65,
            allowBlank: true,
            align: 'center',
            Cell: ({ row }) => (
               <Image
                  width={25}
                  url={row?.original?.PictureURL}
                  alt={`${row?.original?.FirstName} ${row?.original?.LastName}`}
               />
            ),
         },
         {
            Header: 'Role',
            accessor: 'Role',
            width: 120,
            minWidth: 20,
            align: 'center',
            Cell: (cell: { row: { original: { Role: string } } }) => (
               <TableColumnCellWrapper
                  content={cell.row.original.Role ?? '-'}
                  disabled={rowIsDisabled(cell.row)}
               />
            ),
         },
         {
            Header: 'First Name',
            accessor: 'FirstName',
            Cell: (cell: { row: { original: { FirstName: string } } }) => (
               <TableColumnCellWrapper
                  content={cell.row.original.FirstName ?? '-'}
                  disabled={rowIsDisabled(cell.row)}
               />
            ),
            width: 120,
            minWidth: 20,
         },
         {
            Header: 'Last Name',
            accessor: 'LastName',
            Cell: (cell: { row: { original: { LastName: string } } }) => (
               <TableColumnCellWrapper
                  content={cell.row.original.LastName ?? '-'}
                  disabled={rowIsDisabled(cell.row)}
               />
            ),
            width: 120,
            minWidth: 20,
         },
         {
            Header: 'Email Address',
            accessor: 'Email',
            width: 200,
            minWidth: 20,
            Cell: (cell: {
               row: { original: { Email: string; RoleId: string } }
            }) => (
               <TableColumnCellWrapper
                  content={
                     cell.row.original.RoleId !== UserRoles.VIRTUAL.toString()
                        ? cell.row.original.Email
                        : '-'
                  }
                  disabled={rowIsDisabled(cell.row)}
               />
            ),
         },
         {
            Header: 'Phone',
            accessor: 'Phone',
            width: 150,
            minWidth: 20,
            Cell: (cell: { row: { original: { Phone: string } } }) => (
               <TableColumnCellWrapper
                  content={cell.row.original.Phone ?? '-'}
                  disabled={rowIsDisabled(cell.row)}
               />
            ),
         },
         {
            Header: 'Address',
            accessor: 'Address',
            Cell: (cell: { row: { original: { Address: string } } }) => (
               <TableColumnCellWrapper
                  content={cell.row.original.Address ?? '-'}
                  disabled={rowIsDisabled(cell.row)}
               />
            ),
            width: 200,
            minWidth: 20,
         },
         {
            Header: 'Allow Delete Tool',
            accessor: 'RestrictItemDelete',
            width: 150,
            minWidth: 20,
            align: 'center',
            Cell: (cell: {
               row: { original: { RestrictItemDelete: boolean } }
            }) => (
               <TrueOrFalse status={!cell.row.original.RestrictItemDelete} />
            ),
         },
         {
            Header: 'Allow Add Tool',
            accessor: 'AllowAddTool',
            width: 150,
            minWidth: 20,
            align: 'center',
            Cell: (cell: { row: { original: { AllowAddTool: boolean } } }) => (
               <TrueOrFalse status={cell.row.original.AllowAddTool} />
            ),
         },
         {
            Header: 'Auto Accept Tool Assignments',
            accessor: 'AutoAcceptToolAssignments',
            width: 150,
            minWidth: 20,
            align: 'center',
            Cell: (cell: {
               row: {
                  original: {
                     AutoAcceptToolAssignments: boolean
                  }
               }
            }) => (
               <TrueOrFalse
                  status={cell.row.original.AutoAcceptToolAssignments}
               />
            ),
         },
         {
            Header: 'Allow Tool Transfer To Virtual Connections',
            accessor: 'AllowToolTransferToVirtualConnections',
            width: 150,
            minWidth: 20,
            align: 'center',
            Cell: (cell: {
               row: {
                  original: {
                     AllowToolTransferToVirtualConnections: boolean
                  }
               }
            }) => (
               <TrueOrFalse
                  status={
                     cell.row.original.AllowToolTransferToVirtualConnections
                  }
               />
            ),
         },
         {
            Header: 'Scan To Accept Required',
            accessor: 'RequireScanToAccept',
            width: 150,
            minWidth: 20,
            align: 'center',
            Cell: (cell: {
               row: {
                  original: { RequireScanToAccept: boolean }
               }
            }) => (
               <TrueOrFalse status={cell.row.original.RequireScanToAccept} />
            ),
         },
         ...(!!settings?.EnableLocationTracking
            ? [
                 {
                    Header: 'Location',
                    accessor: 'Location',
                    width: 200,
                    minWidth: 20,
                    Cell: (cell: {
                       row: { original: { Location: string } }
                    }) => (
                       <TableColumnCellWrapper
                          content={cell.row.original.Location ?? '-'}
                          disabled={rowIsDisabled(cell.row)}
                       />
                    ),
                 },
              ]
            : []),
      ],
      [crudData]
   )

   useEffect(() => {
      setPath(crudPaths, key)
   }, [])

   const handleSnackbar = (message: string, severity: Severity) => {
      setSnackbarState((prevState) => ({ ...prevState, message, severity }))
   }

   const handleSuccessfulImport = () => {
      refreshConnections()
      resetAndCloseModal()
   }

   useEffect(() => {
      if (isSuccessUpdate) {
         handleSnackbar('Connection has been updated.', Severity.SUCCESS)
         resetAndCloseModal()
      }
   }, [isSuccessUpdate])

   useEffect(() => {
      if (isSuccessCreate) {
         handleSnackbar('Connection has been created.', Severity.SUCCESS)
         refetchProfile()
         resetAndCloseModal()
      }
   }, [isSuccessCreate])

   useEffect(() => {
      if (isSuccessRemove) {
         handleSnackbar('Connection has been removed.', Severity.SUCCESS)
         refetchProfile()
         resetAndCloseModal()
      }
   }, [isSuccessRemove])

   useEffect(() => {
      handleSnackbar(error, Severity.ERROR)
   }, [isErrorRemove])

   useEffect(() => {
      handleSnackbar(error, Severity.ERROR)
   }, [isErrorUpdate])

   useEffect(() => {
      handleSnackbar(error, Severity.ERROR)
   }, [error])

   useEffect(() => {
      if (isErrorCreate) {
         handleSnackbar(error, Severity.ERROR)
         setOpenWarningModal(true)
      }
   }, [isErrorCreate])

   const showSnackbar =
      isSuccessUpdate ||
      isSuccessCreate ||
      isSuccessRemove ||
      isErrorRemove ||
      isErrorCreate ||
      isErrorUpdate

   const showLoadingSpinner =
      isLoading || isUpdating || isCreating || isRemoving || isRefetching

   return (
      <div>
         {showLoadingSpinner && <LoadingOverlay />}
         <SnackBar
            message={snackbarState.message}
            open={showSnackbar}
            severity={snackbarState.severity}
            disableAutoClose={snackbarState.disableAutoClose}
            resetMessageStateHandler={() => setSnackbarState(defaultSnackbar)}
         />

         <ImportModal
            variant="Connections"
            isOpen={isImportModalVisible}
            handleClose={handleClose}
            handleSuccessfulImport={handleSuccessfulImport}
            importSelectedFile={importConnections}
            isImportingData={isImporting}
            importError={importError}
            importFileErrors={importFileErrors}
            isImportingSuccess={isImportSuccess}
            totalImported={totalImported}
         />

         <WarningModal
            isModalOpen={openWarningModal}
            handleClose={() => setOpenWarningModal(false)}
            title={'Connection'}
            message={
               connectionsLimitReached
                  ? 'Need more Connections?'
                  : 'The connection cannot be assigned'
            }
            children={<Alert message={snackbarState.message} />}
            hasChildren={true}
            zIndex="9999"
            primaryButtonTitle="Close"
            primaryButtonMethod={() => setOpenWarningModal(false)}
            primaryButtonVariant={'tertiary'}
         />

         <PageHeader title={tableTitle}>
            {!isEmployee && (
               <>
                  <Button
                     onClick={() => showActionForm(Actions.Add, '')}
                     preserveText
                     variant="plain"
                     minWidth="0"
                  >
                     <Svg id="plus" /> {addButtonText}
                  </Button>
                  <Button
                     onClick={handleOpenImportModal}
                     preserveText
                     variant="plain"
                     minWidth="0"
                  >
                     <ImportIcon /> Import Connections
                  </Button>
               </>
            )}

            <Button
               onClick={showDownloadModal}
               preserveText
               variant="plain"
               minWidth="0"
            >
               <Svg id="download" width={8} height={8} /> Export to Excel
            </Button>
         </PageHeader>

         <div className={styles.searchWrapper}>
            <div className={styles.searchWrapperSearch}>
               <Search
                  handleQuery={handleSearch}
                  searchMode={searchMode}
                  setSearchMode={setSearchMode}
                  pageSized={pageSized}
                  placeHolder={placeholder}
                  setSearchCriteria={setSearchCriteria}
               />
            </div>
            <div className={styles.searchWrapperFilter}>
               <FilterButton
                  isActive={!isEmptyObject(savedFilters)}
                  onClick={() => setFiltersOpen(!filtersOpen)}
               />
            </div>
         </div>

         <FilterTags filters={savedFilters} onClick={handleRemoveTag} />

         <TableWrapper
            isLoading={isLoading}
            data={crudData}
            columns={columns}
            totalCount={totalItems}
            getItems={handleGetItems}
            takeItems={PAGINATION.take}
            skipItems={PAGINATION.skip}
            setSearchMode={setSearchMode}
            searchMode={searchMode}
            query={query}
            isPageReset={isPageReset}
            setPageSized={setPageSized}
            extraClassName={extraClassName}
            searchCriteria={searchCriteria}
            getItem={enableViewing ? handleRowClick : null}
         />

         <SlidePanel isOpen={filtersOpen} onClose={() => setFiltersOpen(false)}>
            <Filters onReset={handleResetFilters} onSave={handleFilterSave}>
               <form ref={formRef}>
                  <DropdownFilter
                     defaultValue={tmpFilters?.role?.value as Option[]}
                     id="role"
                     onChange={handleDropdownChange}
                     options={allRoleOptions}
                     title="Role"
                  />

                  <TextFilter
                     defaultValue={tmpFilters?.firstName?.value as string}
                     error={errors?.firstName}
                     id="firstName"
                     onChange={modify}
                     title="First name"
                  />

                  <TextFilter
                     defaultValue={tmpFilters?.lastName?.value as string}
                     error={errors?.lastName}
                     id="lastName"
                     onChange={modify}
                     title="Last name"
                  />

                  <TextFilter
                     defaultValue={tmpFilters?.emailAddress?.value as string}
                     error={errors?.emailAddress}
                     id="emailAddress"
                     onChange={modify}
                     title="Email address"
                  />

                  {!!settings?.EnableLocationTracking && (
                     <DropdownFilter
                        defaultValue={tmpFilters?.locations?.value as Option[]}
                        id="locations"
                        onChange={handleDropdownChange}
                        options={locationOptions}
                        title="Location"
                     />
                  )}
               </form>
            </Filters>
         </SlidePanel>

         <Modal
            isModalVisible={isExportModalVisible}
            closeModal={() => setIsExportModalVisible(false)}
            title={`Export connections`}
         >
            <CsvDownloadForm
               primaryActionText="Export"
               secondaryActionText="Cancel"
               criteria={''}
               data={crudData}
               fileName="Connections.xlsx"
               closeModal={handleClose}
               path={PATH.CONNECTIONS.DOWNLOAD}
               query={searchCriteria}
               fullDownload={downloadRequest}
               filters={filterValues}
               setIsDownloading={setIsDownloading}
               isDownloading={isDownloading}
               defaultParams={{}}
               buttonRef={buttonRef}
            />
         </Modal>

         <Modal
            isModalVisible={isDeleteModalVisible}
            closeModal={handleClose}
            title={`Delete${modalTitle}`}
         >
            <div className={connectionsStyles.deleteForm}>
               <p>Are you sure you wish to delete this connection?</p>

               {snackbarState.message &&
                  snackbarState.severity === Severity.ERROR && (
                     <Alert message={snackbarState.message} />
                  )}

               <Row gutterWidth={20}>
                  <Col xs={6}>
                     <Button
                        minWidth="100%"
                        variant="danger"
                        onClick={handleDeleteConnection}
                        loading={isRemoving}
                     >
                        Delete
                     </Button>
                  </Col>
                  <Col xs={6}>
                     <Button
                        minWidth="100%"
                        variant="tertiary"
                        onClick={handleClose}
                        isReset
                     >
                        Cancel
                     </Button>
                  </Col>
               </Row>
            </div>
         </Modal>

         <Modal
            isModalVisible={isAddEditModalVisible}
            closeModal={handleClose}
            title={`${!!selectedConnection?.ID ? 'Edit' : 'Add'}${modalTitle}`}
         >
            {isAddEditModalVisible && (
               <form
                  className={connectionsStyles.form}
                  noValidate
                  onSubmit={onSubmit}
               >
                  <input
                     name="RoleId"
                     id="RoleId"
                     type="hidden"
                     value={selectedConnection?.RoleId}
                  />
                  <input
                     name="LocationID"
                     id="LocationID"
                     type="hidden"
                     value={selectedConnection?.Location?.value}
                  />
                  <input
                     name="ID"
                     id="ID"
                     type="hidden"
                     value={selectedConnection?.ID}
                  />
                  <input
                     name="RestrictItemDelete"
                     id="RestrictItemDelete"
                     type="hidden"
                     value={selectedConnection?.RestrictItemDelete?.toString()}
                  />
                  <input
                     name="AllowAddTool"
                     id="AllowAddTool"
                     type="hidden"
                     value={selectedConnection?.AllowAddTool?.toString()}
                  />
                  <input
                     name="AutoAcceptToolAssignments"
                     id="AutoAcceptToolAssignments"
                     type="hidden"
                     value={selectedConnection?.AutoAcceptToolAssignments?.toString()}
                  />
                  <input
                     name="AllowToolTransferToVirtualConnections"
                     id="AllowToolTransferToVirtualConnections"
                     type="hidden"
                     value={selectedConnection?.AllowToolTransferToVirtualConnections?.toString()}
                  />
                  {!settings.ForceScanToAccept && ( // if enabled globally through settings we don't need this
                     <input
                        name="RequireScanToAccept"
                        id="RequireScanToAccept"
                        type="hidden"
                        value={selectedConnection?.RequireScanToAccept?.toString()}
                     />
                  )}
                  <Dropdown
                     error={validationErrors?.RoleId}
                     defaultValue={selectedConnection?.Role}
                     name="Role"
                     onChange={(options: any) =>
                        handleItemDropdownChange('Role', options)
                     }
                     options={roleOptions}
                     id="Role"
                     label="Role"
                     placeholder="Role"
                     required
                     disabled={roleDropdownDisabled}
                  />

                  <Row gutterWidth={20}>
                     <Col xs={6}>
                        <Input
                           id="FirstName"
                           error={validationErrors?.FirstName}
                           label="First name"
                           value={selectedConnection?.FirstName}
                           disabled={isEmployee}
                           required
                           inputProps={{
                              autoComplete: 'off',
                           }}
                        />
                     </Col>
                     <Col xs={6}>
                        <Input
                           id="LastName"
                           error={validationErrors?.LastName}
                           label="Last name"
                           value={selectedConnection?.LastName}
                           disabled={isEmployee}
                           required
                           inputProps={{
                              autoComplete: 'off',
                           }}
                        />
                     </Col>
                     <Col xs={6}>
                        <Input
                           id="Email"
                           error={validationErrors?.Email}
                           label="Email address"
                           value={
                              !isVirtualConnection && selectedConnection?.Email
                           }
                           disabled={isVirtualConnection || isEmployee}
                           required={!isVirtualConnection}
                           inputProps={{
                              type: 'email',
                           }}
                           noMargin={!isEditing}
                        />
                     </Col>
                     <Col xs={6}>
                        <Input
                           id="Phone"
                           error={validationErrors?.Phone}
                           label="Phone"
                           value={
                              !isVirtualConnection && selectedConnection?.Phone
                           }
                           disabled={isVirtualConnection || isEmployee}
                        />
                     </Col>

                     {!!settings?.EnableLocationTracking && (
                        <Col xs={6}>
                           <Dropdown
                              error={validationErrors?.LocationID}
                              defaultValue={selectedConnection?.Location}
                              name="Location"
                              onChange={(options: any) =>
                                 handleItemDropdownChange('Location', options)
                              }
                              options={locationOptions}
                              id="Location"
                              label="Default location"
                              placeholder="Location"
                              disabled={isVirtualConnection || isEmployee}
                              noMargin
                           />
                        </Col>
                     )}

                     {isEditing && (
                        <Col xs={6}>
                           <Input
                              id="SetPassword"
                              error={validationErrors?.SetPassword}
                              label="Set Password"
                              value={
                                 !isVirtualConnection &&
                                 selectedConnection?.SetPassword
                              }
                              disabled={isVirtualConnection || isEmployee}
                              inputProps={{
                                 autoComplete: 'off',
                                 type: 'password',
                              }}
                              noMargin
                           />
                        </Col>
                     )}
                  </Row>

                  {!isEditing && (
                     <>
                        <hr />

                        <Row gutterWidth={20}>
                           <Col xs={6}>
                              <Input
                                 id="Password"
                                 error={validationErrors?.Password}
                                 label="Password"
                                 value={
                                    !isVirtualConnection &&
                                    selectedConnection?.Password
                                 }
                                 disabled={isVirtualConnection || isEmployee}
                                 required={!isVirtualConnection}
                                 inputProps={{
                                    autoComplete: 'new-password',
                                    type: 'password',
                                 }}
                                 noMargin
                              />
                           </Col>
                           <Col xs={6}>
                              <Input
                                 id="ConfirmPassword"
                                 error={validationErrors?.ConfirmPassword}
                                 label="Confirm password"
                                 value={
                                    !isVirtualConnection &&
                                    selectedConnection?.ConfirmPassword
                                 }
                                 disabled={isVirtualConnection || isEmployee}
                                 required={!isVirtualConnection}
                                 noMargin
                                 inputProps={{
                                    autoComplete: 'new-password',
                                    type: 'password',
                                 }}
                              />
                           </Col>
                        </Row>
                     </>
                  )}

                  <hr />

                  <>
                     <Row gutterWidth={20}>
                        <Col xs={6}>
                           <Input
                              id="Address1"
                              error={validationErrors?.Address1}
                              label="Address Line 1"
                              value={
                                 !isVirtualConnection &&
                                 selectedConnection?.Address1
                              }
                              disabled={isVirtualConnection || isEmployee}
                           />
                           <Input
                              id="Address2"
                              error={validationErrors?.Address2}
                              label="Address Line 2"
                              value={
                                 !isVirtualConnection &&
                                 selectedConnection?.Address2
                              }
                              disabled={isVirtualConnection || isEmployee}
                           />
                           <Input
                              id="Address3"
                              error={validationErrors?.Address3}
                              label="Address Line 3"
                              value={
                                 !isVirtualConnection &&
                                 selectedConnection?.Address3
                              }
                              disabled={isVirtualConnection || isEmployee}
                              noMargin
                           />
                        </Col>
                        <Col xs={6}>
                           <Input
                              id="City"
                              error={validationErrors?.City}
                              label="City"
                              value={
                                 !isVirtualConnection &&
                                 selectedConnection?.City
                              }
                              disabled={isVirtualConnection || isEmployee}
                           />
                           <Input
                              id="State"
                              error={validationErrors?.State}
                              label="State/Province"
                              value={
                                 !isVirtualConnection &&
                                 selectedConnection?.State
                              }
                              disabled={isVirtualConnection || isEmployee}
                           />
                           <Input
                              id="ZipCode"
                              error={validationErrors?.ZipCode}
                              label="Postal Code"
                              value={
                                 !isVirtualConnection &&
                                 selectedConnection?.ZipCode
                              }
                              disabled={isVirtualConnection || isEmployee}
                              noMargin
                           />
                        </Col>
                     </Row>

                     <hr />
                  </>

                  {isAdminConnection && (
                     <>
                        <Row>
                           <Col xs={12} md={5}>
                              <div className={connectionsStyles.addItemToggle}>
                                 <span
                                    className={connectionsStyles.toggleField}
                                 >
                                    <Toggle
                                       id="RestrictItemDelete"
                                       size="md"
                                       isChecked={
                                          selectedConnection?.RestrictItemDelete ===
                                          false
                                       }
                                       onToggle={handleToggle}
                                       disabled={isEmployee}
                                    />
                                 </span>
                                 <span className={toggleLabelClassName}>
                                    Allow Delete Tool
                                 </span>
                              </div>
                           </Col>
                           <Col xs={12} md={7}>
                              <p
                                 className={classNames(
                                    locationStyles.description,
                                    locationStyles.warehouseDescription
                                 )}
                              >
                                 Enable this option to allow an admin to delete
                                 items.
                              </p>
                           </Col>
                        </Row>

                        <hr />
                     </>
                  )}

                  {isEmployeeConnection && (
                     <>
                        <Row>
                           <Col xs={12} md={5}>
                              <div className={connectionsStyles.addItemToggle}>
                                 <span
                                    className={connectionsStyles.toggleField}
                                 >
                                    <Toggle
                                       id="AllowAddTool"
                                       size="md"
                                       isChecked={
                                          selectedConnection?.AllowAddTool
                                       }
                                       onToggle={handleToggle}
                                       disabled={isEmployee}
                                    />
                                 </span>
                                 <span className={toggleLabelClassName}>
                                    Allow Add Tool
                                 </span>
                              </div>
                           </Col>
                           <Col xs={12} md={7}>
                              <p
                                 className={classNames(
                                    locationStyles.description,
                                    locationStyles.warehouseDescription
                                 )}
                              >
                                 Enable this option to allow an employee to add
                                 a STANDARD item. Item will be loaned to the
                                 employee automatically when added.
                              </p>
                           </Col>
                        </Row>

                        <hr />

                        <Row>
                           <Col xs={12} md={5}>
                              <div className={connectionsStyles.toggle}>
                                 <span
                                    className={connectionsStyles.toggleField}
                                 >
                                    <Toggle
                                       id="AutoAcceptToolAssignments"
                                       size="md"
                                       isChecked={
                                          selectedConnection?.AutoAcceptToolAssignments
                                       }
                                       onToggle={handleToggle}
                                       disabled={isEmployee}
                                    />
                                 </span>
                                 <span className={toggleLabelClassName}>
                                    Auto Accept Item Assignments
                                 </span>
                              </div>
                           </Col>
                           <Col xs={12} md={7}>
                              <p
                                 className={classNames(
                                    locationStyles.description,
                                    locationStyles.warehouseDescription
                                 )}
                              >
                                 Enable this option to force acceptance when an
                                 item is loaned or transferred to an employee.
                                 This allows you to bypass ShareMyToolbox's
                                 accountability feature.
                              </p>
                           </Col>
                        </Row>

                        <hr />

                        <Row>
                           <Col xs={12} md={5}>
                              <div className={connectionsStyles.toggle}>
                                 <span
                                    className={connectionsStyles.toggleField}
                                 >
                                    <Toggle
                                       id="AllowToolTransferToVirtualConnections"
                                       size="md"
                                       isChecked={
                                          selectedConnection?.AllowToolTransferToVirtualConnections
                                       }
                                       onToggle={handleToggle}
                                       disabled={isEmployee}
                                    />
                                 </span>
                                 <span className={toggleLabelClassName}>
                                    Allow Item Transfer To Virtual Connections
                                 </span>
                              </div>
                           </Col>
                           <Col xs={12} md={7}>
                              <p
                                 className={classNames(
                                    locationStyles.description,
                                    locationStyles.warehouseDescription
                                 )}
                              >
                                 Enable this option to allow an employee to
                                 transfer tools to a virtual connection. By
                                 default, virtual connections always auto-accept
                                 transactions, so tools can be transferred with
                                 no accountability if you give this permission.
                              </p>
                           </Col>
                        </Row>

                        <hr />
                        {/*if disabled globally through settings we don't need to display this */}
                        {!settings.ForceScanToAccept && (
                           <>
                              <Row>
                                 <Col xs={12} md={5}>
                                    <div className={connectionsStyles.toggle}>
                                       <span
                                          className={
                                             connectionsStyles.toggleField
                                          }
                                       >
                                          <Toggle
                                             id="RequireScanToAccept"
                                             size="md"
                                             isChecked={
                                                selectedConnection?.RequireScanToAccept
                                             }
                                             onToggle={handleToggle}
                                             disabled={isEmployee}
                                          />
                                       </span>
                                       <span className={toggleLabelClassName}>
                                          Scan to Accept Required
                                       </span>
                                    </div>
                                 </Col>
                                 <Col xs={12} md={7}>
                                    <p
                                       className={classNames(
                                          locationStyles.description,
                                          locationStyles.warehouseDescription
                                       )}
                                    >
                                       Enable this option to force the scanning
                                       of Items for acceptance. This will
                                       capture GPS location upon scanning. Note,
                                       Items without a barcode will still
                                       display Accept & Decline buttons.
                                    </p>
                                 </Col>
                              </Row>

                              <hr />
                           </>
                        )}
                     </>
                  )}

                  <Row gutterWidth={20}>
                     {isEmployee ? (
                        <Col xs={12}>
                           <Button
                              minWidth="100%"
                              variant="tertiary"
                              onClick={handleClose}
                              isReset
                           >
                              Cancel
                           </Button>
                        </Col>
                     ) : (
                        <>
                           <Col xs={6}>
                              <Button
                                 minWidth="100%"
                                 loading={isCreating || isUpdating}
                              >
                                 Save
                              </Button>
                           </Col>
                           <Col xs={6}>
                              <Button
                                 minWidth="100%"
                                 variant="tertiary"
                                 onClick={handleClose}
                                 isReset
                              >
                                 Cancel
                              </Button>
                           </Col>
                        </>
                     )}
                  </Row>
               </form>
            )}
         </Modal>

         {isRoleChangeWarningVisible && (
            <WarningModal
               isModalOpen={isRoleChangeWarningVisible}
               handleClose={() => setIsRoleChangeWarningVisible(false)}
               title={'Role Change'}
               message={warningModalRoleChangeMessage}
               zIndex="9999"
               primaryButtonTitle="Acknowledge"
               primaryButtonMethod={() => setIsRoleChangeWarningVisible(false)}
               primaryButtonVariant={'primary'}
            />
         )}

         {isConnectionLimitPromptVisible && (
            <WarningModal
               isModalOpen={isConnectionLimitPromptVisible}
               handleClose={() => setIsConnectionLimitPromptVisible(false)}
               title={'Connection Limit Reached'}
               hasChildren
               message="Need more Connections?"
               children={<Alert message={connectionLimitMessage} />}
               zIndex="9999"
               primaryButtonTitle={'Close'}
               primaryButtonVariant={'tertiary'}
               primaryButtonMethod={() =>
                  setIsConnectionLimitPromptVisible(false)
               }
            />
         )}
      </div>
   )
}

export default Connections
